<template>
  <vx-card>
    <form-wizard color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-danger), 1)" :title="null" :subtitle="null" finishButtonText="Submit">
      <tab-content :title="$i18n.t('LetterDetail')" class="mb-5" icon="feather icon-file-text" :before-change="validateStep1">

        <!-- tab 1 content -->
        <form data-vv-scope="step-1">
          <div class="vx-row">
            <div class="vx-col md:w-3/12 w-full mt-5">
              <label class="vs-input--label">{{$i18n.t('LetterDate')}}</label>
              <datepicker format="d/MM/yyyy" name="tanggal_surat" :value="form.tanggal_surat" @input="form.tanggal_surat = $moment($event)" ></datepicker>
              <!--            <vs-input :label="$i18n.t('LetterDate')" type="date"  v-model="form.tanggal_surat" name="tanggal_surat" class="w-full" v-validate="'required'" />-->
            </div>
            <div class="vx-col md:w-3/12 w-full mt-5">
              <label class="vs-input--label">{{$i18n.t('DateOfReceipt')}}</label>
              <datepicker format="d/MM/yyyy" name="tanggal_terima" :value="form.tanggal_terima" @input="form.tanggal_terima = $moment($event)" v-validate="'required'"></datepicker>
              <span class="text-danger">{{ errors.first(`step-1.tanggal_terima`) }}</span>
            </div>
            <div class="vx-col md:w-3/12 w-full mt-5">
              <vs-input :label="$i18n.t('Receiver')" v-model="form.penerima" name="penerima" class="w-full" v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-1.penerima') }}</span>
            </div>
            <div class="vx-col md:w-3/12 w-full mt-5">
              <vs-input :label="$i18n.t('LetterNumber')" v-model="form.nomor_surat" name="nomor_surat" class="w-full" v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-1.nomor_surat') }}</span>
            </div>
          </div>
        </form>
      </tab-content>

      <!-- tab 2 content -->
      <tab-content :title="$i18n.t('OriginOfTheLetter')" class="mb-5" icon="feather icon-briefcase" :before-change="validateStep2">
        <form data-vv-scope="step-2">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <vs-input :label="$i18n.t('CompanyName')" name="nama_perusahaan" v-model="form.nama_perusahaan" class="w-full mt-4" v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-2.nama_perusahaan') }}</span>

              <vs-input :label="$i18n.t('ContactPerson')" name="cp" v-model="form.cp" class="w-full mt-4" v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-2.cp') }}</span>

              <vs-input :label="$i18n.t('PhoneFax')" name="telp_fax" v-model="form.telp_fax" class="w-full mt-4" v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-2.telp_fax') }}</span>
            </div>
            <div class="vx-col md:w-1/2 w-full mt-4">
              <label for="" class="vs-input--label">{{$i18n.t('Adress')}}</label>
              <vs-textarea v-model="form.alamat" name="alamat" class="mb-0" rows="8" v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-2.alamat') }}</span>
            </div>
          </div>
        </form>
      </tab-content>

      <!-- tab 3 content -->
      <tab-content :title="$i18n.t('Attachment')" class="mb-5" icon="feather icon-paperclip" :before-change="validateStep3">
        <form data-vv-scope="step-3">
          <div class="vx-row">

            <div class="vx-col md:w-1/1 w-full">
              <vs-input :label="$i18n.t('Subject')" v-model="form.perihal" name="perihal" class="w-full mt-4" v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-3.perihal') }}</span>
            </div>

            <div class="vx-col md:w-1/3 w-full">
              <vs-input :label="$i18n.t('Page')" v-model="form.page" name="halaman" class="w-full mt-4" v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-3.halaman') }}</span>
            </div>

            <div class="vx-col md:w-2/3 w-full">
              <vs-input :label="$i18n.t('Purpose')" v-model="form.tujuan_surat" name="tujuan_surat" class="w-full mt-4" v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-3.tujuan_surat') }}</span>
            </div>

            <div class="vx-col md:w-1/3 w-full">
              <vs-input :label="$i18n.t('FileLocation')" v-model="form.lokasi_file" name="lokasi_file" class="w-full mt-4" v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-3.lokasi_file') }}</span>
            </div>

            <!--          <div class="vx-col md:w-1/1 w-full">-->
            <!--            <vs-upload single-upload :text="$i18n.t('Attachment')" name="file" :data="files" class="file-upload" />-->
            <!--            <span class="text-danger">{{ errors.first('step-3.file') }}</span>-->
            <!--            <vs-button color="primary" icon-pack="feather" type="border" icon="icon-paperclip" v-if="this.id">{{ form.file }}</vs-button>-->
            <!--          </div>-->

            <div class="vx-col md:w-1/3 w-full">
              <vs-input :label="$i18n.t('Attachment')" type="file" @change="handleFileChange($event.target.name, $event.target.files)" name="file" class="w-full mt-4" />
              <span class="text-danger">{{ errors.first('step-3.file') }}</span>
            </div>

          </div>
        </form>
      </tab-content>
    </form-wizard>
  </vx-card>
</template>

<script>
// Common
import Datepicker from 'vuejs-datepicker';
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {mapGetters} from 'vuex'
import { Validator } from 'vee-validate';

export default {
  // Data
  data() {
    return {
      dict: {
        custom: {
          // Tab 1
          tanggal_terima: {
            required: this.$i18n.t('DateOfReceipt') + ' is required',
          },
          penerima: {
            required: this.$i18n.t('Receiver') + ' is required',
          },
          nomor_surat: {
            required: this.$i18n.t('LetterNumber') + ' is required',
          },

          // Tab 2
          nama_perusahaan: {
            required: this.$i18n.t('CompanyName') + ' is required',
          },
          cp: {
            required: this.$i18n.t('ContactPerson') + ' is required',
          },
          telp_fax: {
            required: this.$i18n.t('PhoneFax') + ' is required',
          },
          alamat: {
            required: this.$i18n.t('Adress') + ' is required',
          },

          // Tab 3
          perihal: {
            required: this.$i18n.t('Subject') + ' is required',
          },
          halaman: {
            required: this.$i18n.t('Page') + ' is required',
          },
          tujuan_surat: {
            required: this.$i18n.t('Purpose') + ' is required',
          },
          lokasi_file: {
            required: this.$i18n.t('FileLocation') + ' is required',
          },
          file: {
            required: this.$i18n.t('File') + ' is required',
          },
        }
      },
      form: {},
      id: false,
    }
  },

  // Methods
  methods: {

    // validate per state
    validateStep1() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-1').then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validateStep2() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-2").then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validateStep3() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-3").then(result => {
          if (result) {
            this.submit();
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },

    // append form data
    appendFormData (formData, obj) {
        Object.keys(obj).forEach(key => {
          formData.append(key, obj[key]);
        });

        return formData;
    },

    // on Submit
    submit () {
      this.loading();

      let payload = { body: new FormData() }, action;
      payload.body = this.appendFormData(payload.body, this.form);

      if (this.id) {
        payload.body.append('_method', 'PUT');
        payload.id = this.id;

        action = this.$store.dispatch("incomingLetter/updateItem", payload);
      } else {
        action = this.$store.dispatch("incomingLetter/storeItem", payload);
      }

      action.then((response) => {
        this.$vs.loading.close();
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          this.$router.push({name: 'incoming-letter'});
        });
      }).catch(err => {

        // set error
        if (err.data.errors) {
          this.error = err.data.errors;
        }

        // show message error
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })

      });
    },

    // on Change
    handleFileChange (event, files) {
      this.form.file = files[0];
    },

    loading () {
      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: 'Data is being processed'
      })
    },

  },

  // Components
  components: {
    FormWizard,
    TabContent,
    Datepicker
  },

  // Computed
  computed: {

  },

  // Created
  created() {

    if (this.$route.params.id) {
      this.id = this.$secure.decrypt(this.$route.params.id);
      this.$store.dispatch('incomingLetter/detailItem', { id: this.id }).then((response) => {
        this.form = response.data.values;
      });
    } else {
      this.$store.commit('incomingLetter/CLEAR_ITEM');
    }

    // register custom messages
    Validator.localize('en', this.dict);
  }
}
</script>

<style>
.con-input-upload {
  box-sizing: border-box;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.con-upload .con-img-upload {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>

